import { SiteRouter } from './router';
import { Bus, onAuthFail, onAuthOk, onMessage } from './bus';
import { Auth } from './auth';
import init from '../wasm/wasm_pipe.js';
import { zoom_out, rewind, fast_forward, zoom_in } from '../wasm/wasm_pipe';
import { registerThemes } from './echart_themes';

Paddle.Setup({ vendor: 169710 });
//Paddle.Environment.set('sandbox');

declare global {
    interface Window {
        router: SiteRouter;
        bus: Bus;
        auth: Auth;
        login: any;
        changeTimePeriod: any;
        authFailed: any;
        onAuthOk: any;
        onMessage: any;
    }
}
window.authFailed = onAuthFail;
window.onAuthOk = onAuthOk;
window.onMessage = onMessage;

await init();
//console.log("WASM loaded");

window.auth = new Auth;
window.bus = new Bus();
window.router = new SiteRouter();
window.router.initialRoute();

console.log("Initializing time period");
window.changeTimePeriod = (period: string) => changeGraphPeriod(period);

// ECharts Themes
registerThemes();

// 10 Second interval for refreshing the page
window.setInterval(() => {
    window.bus.updateConnected();    
    window.router.ontick();
}, 30000);

// Faster interval for tracking the WSS connection
window.setInterval(() => {
    window.bus.updateConnected();
    try {
        window.bus.sendQueue();
    } catch (e) {
        //console.log("Error sending queue: " + e);
    }
}, 500);

function changeGraphPeriod(period: string) {
    if (period == "custom") {
        let number = document.getElementById("relativeNumber") as HTMLInputElement;
        let unit = document.getElementById("relativeUnit") as HTMLSelectElement;
        let numberValue = parseInt(number.value);
        if (isNaN(numberValue)) {
            numberValue = 1;
        }
        let unitValue = unit.value;
        let combined = numberValue + unitValue;
        //console.log("Combined: " + combined);
        period = combined;
    } else if (period == "range") {
        // Get the parts
        let startDateE = document.getElementById("graphStartDate") as HTMLInputElement;
        let startDateString = startDateE.value;
        let endDateE = document.getElementById("graphEndDate") as HTMLInputElement;
        let endDateString = endDateE.value;
        let startTimeE = document.getElementById("graphStartTime") as HTMLInputElement;
        let startTimeString = startTimeE.value;
        let endTimeE = document.getElementById("graphEndTime") as HTMLInputElement;
        let endTimeString = endTimeE.value;

        // Parse the dates
        let startDate = new Date(startDateString + " " + startTimeString);
        let endDate = new Date(endDateString + " " + endTimeString);

        // Convert the dates to UTC
        let start = startDate.toISOString();
        let end = endDate.toISOString();

        // Send it on its way
        window.bus.absoluteTimePeriod(start, end);
        window.router.ontick();
        return;
    } else if (period == "rewind") {
        rewind();
        window.router.ontick();
        return;
    } else if (period == "forward") {
        fast_forward();
        window.router.ontick();
        return;
    } else if (period == "zoomOut") {
        zoom_out();
        window.router.ontick();
        return;
    } else if (period == "zoomIn") {
        zoom_in();
        window.router.ontick();
        return;
    }
    window.bus.changeTimePeriod(period);
    window.router.ontick();
}
