import { request_breadcrumbs } from "../../wasm/wasm_pipe";
import { current_node_id, makeUrl, redactText } from "../helpers";
import { Component } from "./component";

export class CircuitBreadcrumbs implements Component {
    circuitId: string;

    constructor(siteId: string) {
        this.circuitId = siteId;
    }

    wireup(): void {
        //console.log("Requesting circuit breadcrumbs");
        request_breadcrumbs("circuit", this.circuitId);
    }

    ontick(): void {
        request_breadcrumbs("circuit", this.circuitId);
    }

    onmessage(event: any): void {
        if (event.msg == "Breadcrumbs") {
            //console.log("Parents", event);
            let div = document.getElementById("siteName") as HTMLDivElement;
            let html = "";

            for (let i=0; i<event.Breadcrumbs.data.length; i++) {
                let node = event.Breadcrumbs.data[i];
                html += "<a style='text-decoration: underline;' onclick='window.router.goto(\"dashboard\")'> (Root) " + node.name + "</a>";
                html += " <i class=\"fa-solid fa-chevron-right\"></i> ";

                let progress = 0;
                for (let j=0; j<node.crumbs.length; j++) {
                    let p = node.crumbs[j];
                    let url = makeUrl(p.site_type, p.name);
                    html += "<a onclick='window.router.goto(\"" + url + "\")' class='clickable'>" + redactText(p.name) + "</a>";
                    html += " <i class=\"fa-solid fa-chevron-right\"></i> ";
                    progress++;
                }
                html += "<span style='font-weight: bold;'>" + redactText(decodeURI(this.circuitId)) + "</span>";
                html += "<br />";
            }

            div.style.fontSize = "9pt";
            //div.style.columnCount = "2";
            div.innerHTML = html;
        }
    }
}