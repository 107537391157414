export function getValueFromForm(id: string): string {
    let input = document.getElementById(id) as  HTMLInputElement;
    if (input) {
        return input.value;
    }
    return "";
}

export function scaleNumber(n: any, decimals: number = 1): string {
    if (n >= 1000000000000) {
        return (n / 1000000000000).toFixed(decimals) + "T";
    } else if (n >= 1000000000) {
        return (n / 1000000000).toFixed(decimals) + "G";
    } else if (n >= 1000000) {
        return (n / 1000000).toFixed(decimals) + "M";
    } else if (n >= 1000) {
        return (n / 1000).toFixed(decimals) + "K";
    }
    return n;
}

export function siteIcon(type: string): string {
    switch (type) {
        case "circuit": return "<i class='fa fa-user'></i>"; break;
        case "site": return "<i class='fa fa-building'></i>"; break;
        case "ap": return "<i class='fa fa-wifi'></i>"; break;
        default: return "<i class='fa fa-question'></i>";
    }
}

export function usageColor(percent: number): string {
    if (percent > 50 && percent < 75) {
        return "goldenrod";
    } else if (percent >= 75) {
        return "#ffaaaa";
    }
    return "#aaffaa";
}

export function rttColor(n: number): string {
    if (n <= 100) {
        return "#aaffaa";
    } else if (n <= 150) {
        return "goldenrod";
    } else {
        return "#ffaaaa";
    }
}

export function makeUrl(type: string, id: string): string {
    switch (type) {
        case "site": return "site:" + id;
        case "ap": return "ap:" + id;
        case "circuit": return "circuit:" + id;
        default: return "site:" + id;
    }
}

export function getBadRtt(): number {
    let rtt = localStorage.getItem('badRtt');
    if (rtt) {
        return parseInt(rtt);
    } else {
        return 200;
    }
}

export function isRedacted(): boolean {
    let redacted = localStorage.getItem('screenshotMode');
    if (redacted) {
        return redacted == "true";
    } else {
        return false;
    }
}


const phrases = [
    "quSDaq ba’lu’’a’", // Is this seat taken?
    "vjIjatlh", // speak
    "pe’vIl mu’qaDmey", // curse well
    "nuqDaq ‘oH puchpa’’e’", // where's the bathroom?
    "nuqDaq ‘oH tach’e’", // Where's the bar?
    "tera’ngan Soj lujab’a’", // Do they serve Earth food?
    "qut na’ HInob", // Give me the salty crystals
    "qagh Sopbe’", // He doesn't eat gagh
    "HIja", // Yes
    "ghobe’", // No
    "Dochvetlh vIneH", // I want that thing
    "Hab SoSlI’ Quch", // Your mother has a smooth forehead
    "nuqjatlh", // What did you say?
    "jagh yIbuStaH", // Concentrate on the enemy
    "Heghlu’meH QaQ jajvam", // Today is a good day to die
    "qaStaH nuq jay’", // WTF is happening?
    "wo’ batlhvaD", // For the honor of the empire
    "tlhIngan maH", // We are Klingon!
    "Qapla’", // Success!
]

export function redactText(text: string) {
    if (!isRedacted()) return text;
    let sum = 0;
    for (let i = 0; i < text.length; i++) {
        let code = text.charCodeAt(i);
        sum += code;
    }
    sum = sum % phrases.length;
    return phrases[sum];
}

export function lerp_red_green(min: number, max: number, value: number): string {
    const green = [0, 144, 0];
    const yellow = [255, 255, 0];
    const red = [221, 32, 0];

    let percent = (value - min) / (max - min);
    if (percent < 0.5) {
        // Green to yellow
        let pct = percent * 2.0;
        let r = Math.floor(green[0] + ((yellow[0] - green[0]) * pct));
        let g = Math.floor(green[1] + ((yellow[1] - green[1]) * pct));
        let b = Math.floor(green[2] + ((yellow[2] - green[2]) * pct));
        return "rgb(" + r + "," + g + "," + b + ")";
    } else {
        // Yellow to red
        let pct = (percent - 0.5) * 2.0;
        let r = Math.floor(yellow[0] + ((red[0] - yellow[0]) * pct));
        let g = Math.floor(yellow[1] + ((red[1] - yellow[1]) * pct));
        let b = Math.floor(yellow[2] + ((red[2] - yellow[2]) * pct));
        return "rgb(" + r + "," + g + "," + b + ")";
    }
}

export function lerp_white_blue(min: number, max: number, value: number): string {
    const white = [255, 255, 255];
    const blue = [0, 0, 255];
    let percent = (value - min) / (max - min);
    let r = Math.floor(white[0] + ((blue[0] - white[0]) * percent));
    let g = Math.floor(white[1] + ((blue[1] - white[1]) * percent));
    let b = Math.floor(white[2] + ((blue[2] - white[2]) * percent));
    return "rgb(" + r + "," + g + "," + b + ")";
}

export function current_node_id(): string {
    let node = "";
    if (localStorage.getItem("selectedNode") != null) {
        node = localStorage.getItem("selectedNode") as string;
    } else {
        localStorage.setItem("selectedNode", "");
    }
    return node;
}