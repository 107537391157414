import html from './template.html';
import { Page } from '../../page'
import { MenuPage } from '../../menu/menu';
import { Component } from '../../components/component';

class Report {
    name: string;
    url: string;
    icon: string;
    description: string;
    unavailable: boolean;

    constructor(name: string, url: string, icon: string, description: string, unavailable: boolean = false) {
        this.name = name;
        this.url = url;
        this.icon = icon;
        this.description = description;
        this.unavailable = unavailable;
    }
}

const reports: Report[] = [
    new Report("All Sites", "allsites", "fa-earth-europe", "Latency and throughput for all sites on your network."),
    new Report("All Access Points", "allaps", "fa-wifi", "Latency and throughput for all access points on your network."),
    new Report("Sites Capacity", "allsitestp", "fa-temperature-full", "Sites that are approaching maximum utilization."),
    new Report("Access Point Capacity", "allapstp", "fa-fill", "Access Points that are approaching maximum utilization."),
    new Report("Customer Upsell", "allsitestp", "fa-face-smile", "Customer accounts hitting their capacity limit.", true),
    new Report("Low Utilization", "allsitestp", "fa-temperature-full", "Lorem Ipsum.", true),
    new Report("Top 10 Bandwidth Users", "allsitestp", "fa-temperature-full", "Lorem Ipsum.", true),
    new Report("Worst 10 Latency", "allsitestp", "fa-temperature-full", "Lorem Ipsum.", true),
];

export class ReportsPage implements Page {
    menu: MenuPage;
    components: Component[];
    selectedNode: string;

    constructor() {
        this.menu = new MenuPage("reportsDash");
        let container = document.getElementById('mainContent');
        if (container) {
            container.innerHTML = html;
        }
        this.components = [
        ];        
    }

    fillMenu() {
        let menu = "";
        let rowCount = 0;
        let colCount = 0;
        menu += "<div class='row'><div class='col-12'>";
        menu += "<h5><i class='fa-solid fa-book-open'></i> Reports</h5>";
        menu += "</div></div>";
        menu += "<div class='row'>";
        for (let i = 0; i < reports.length; i++) {
            let report = reports[i];
            if (!report.unavailable) {
                menu += "<div class='col-3' onclick='window.router.goto(\"" + report.url + "\")'>";
                menu += "<div class='card cardmenu'>";
                menu += "<div class='card-body'>";
                menu += "<h5 class='card-title'>";
                menu += "<i class='fa-solid " + report.icon + "'></i> " + report.name;
                menu += "</h5>";
                menu += "<p>" + report.description + "</p>";
                menu += "</div>";
                menu += "</div>";
                menu += "</div>";
            } else {
                menu += "<div class='col-3'>";
                menu += "<div class='card' style='background: #ddd'>";
                menu += "<div class='card-body'>";
                menu += "<h5 class='card-title'>";
                menu += "<i class='fa-solid " + report.icon + "'></i> " + report.name;
                menu += "</h5>";
                menu += "<p>(Coming Soon)</p>";
                menu += "</div>";
                menu += "</div>";
                menu += "</div>";
            }
            colCount++;
            if (colCount == 4) {
                rowCount++;
                colCount = 0;
                menu += "</div>";
                menu += "<div class='row'>";
            }
    }
        menu += "</div>";
        let target = document.getElementById("reports") as HTMLDivElement;
        target.innerHTML = menu;
    }

    wireup() {
        this.fillMenu();
        this.components.forEach(component => {
            component.wireup();
        });
    }    

    ontick(): void {
        this.menu.ontick();
        this.components.forEach(component => {
            component.ontick();
        });
    }

    onmessage(event: any) {
        if (event.msg) {
            this.menu.onmessage(event);

            this.components.forEach(component => {
                component.onmessage(event);
            });
        }
    }
}
