import { request_packet_chart } from "../../wasm/wasm_pipe";
import { BandedGraph } from "../banded_graph";
import { initEchartsWithTheme } from "../echart_themes";
import { isRedacted, scaleNumber } from "../helpers";
import { Component, SelectableComponent } from "./component";
import * as echarts from 'echarts';

export class PacketsChart implements SelectableComponent {
    divName: string;
    myChart: BandedGraph;
    chartMade: boolean = false;

    constructor() {
        this.divName = "packetsChart";
    }

    retarget(divName: string): void {
        this.divName = divName;
    }

    wireup(): void {
        this.myChart = new BandedGraph(this.divName);
        request_packet_chart();
    }

    ontick(): void {
        request_packet_chart();
    }

    onmessage(event: any): void {
        if (event.msg == "PacketChart") {
            this.myChart.buildChart(event.PacketChart.nodes);
        }
    }
}