import html from './template.html';
import { Page } from '../../page'
import { Modal } from 'bootstrap';
import { request_billing_estimate, checkout_event } from "../../../wasm/wasm_pipe";

export class PaddlePage implements Page {
    myModal: any = null;

    constructor() {
        let container = document.getElementById('mainContent');
        if (container) {
            container.innerHTML = html;

            // Fire up the login modal
            let paddleModal = document.getElementById('paddleModal');
            if (paddleModal) {
                this.myModal = new Modal(paddleModal, { backdrop: false, keyboard: false });
                         
                this.myModal.show();
            }
        }
    }

    wireup() {
        request_billing_estimate();
    }

    onmessage(event: any) {
        if (event.msg) {
            if (event.msg == "BillingEstimate") {
                //console.log(event);
                let html = "<table class='table'><thead><tr><th>Item</th><th>Price</th></tr></thead><tbody>";
                let quantity = 0;
                let email = "";
                let license = "";
                for (let item of event.BillingEstimate.lines) {
                    html += "<tr><td>" + item.line + "</td><td>$" + parseFloat(item.price_times_100)/100.0 + " (USD)</td></tr>";
                    quantity += item.quantity;
                    email = item.email;
                    license = item.license;
                }
                html += "</tbody></table>";
                let container = document.getElementById('billingEstimate');
                if (container) {
                    container.innerHTML = html;
                }

                // Wire up the button - AFTER we have the billing estimate
                let button = document.getElementById('paddleButton');
                if (button) {
                    button.addEventListener('click', () => {
                        //Paddle.Environment.set("sandbox");
                        Paddle.Setup({ 
                            vendor: 169710
                        });
                        Paddle.Checkout.open({ 
                            product: 825875,
                            quantity: quantity/10,
                            title: "LibreQoS Standard Subscription, 10 Pack",
                            email: email,
                            passthrough: license,
                            loadCallback: () => {
                                // The checkout has been opened
                                //console.log("Checkout loaded"); 
                                checkout_event(1);
                            },
                            closeCallback: () => { 
                                //console.log("Checkout closed"); 
                                checkout_event(2);
                            },
                            successCallback: () => { 
                                //console.log("Checkout success"); 
                                checkout_event(3);
                                window.router.goto("dashboard");
                            },
                        });
                    });
                }       
            }
        }
    }

    ontick(): void {
        // Do nothing
    }
}
