import html from './template.html';
import { Page } from '../../page'
import { MenuPage } from '../../menu/menu';
import { Component } from '../../components/component';
import { request_warnings } from '../../../wasm/wasm_pipe';

export class WarningsPage implements Page {
    menu: MenuPage;
    components: Component[]

    constructor() {
        this.menu = new MenuPage("warningsDash");
        let container = document.getElementById('mainContent');
        if (container) {
            container.innerHTML = html;
        }
    }

    wireup() {
        request_warnings();
    }    

    ontick(): void {
    }

    onmessage(event: any) {
        if (event && event.msg == "Warnings") {
            let html = "<h5>Interface Speed Warnings</h5>";
            html += "<table class='table table-striped'>";
            html += "<thead><th>Device</th><th>Interface</th><th>Speed</th></thead>";
            html += "<tbody>";
            for (let i=0; i<event.Warnings.interface_speed.length; i++) {
                let w = event.Warnings.interface_speed[i];
                html += "<tr>";
                html += "<td>" + w.device_name + "</td>";
                html += "<td>" + w.port_name + "</td>";
                html += "<td>" + w.speed + "</td>";
                html += "</tr>";
            }
            html += "</tbody></table>";

            html += "<h5>Impossible Capacity Warnings</h5>";
            html += "<table class='table table-striped'>";
            html += "<thead><th>Circuit</th><th>Promised Speed</th><th>Estimated Capacity</th></thead>";
            html += "<tbody>";
            for (let i=0; i<event.Warnings.capacity.length; i++) {
                let w = event.Warnings.capacity[i];
                html += "<tr>";
                html += "<td>" + w.circuit_name + "</td>";
                html += "<td>" + w.download_max_mbps + " / " + w.upload_max_mbps + "</td>";
                html += "<td>" + w.capacity_down + " / " + w.capacity_up + "</td>";
                html += "</tr>";
            }
            html += "</tbody></table>";

            html += "<h5>CINR Warnings</h5>";
            html += "<table class='table table-striped'>";
            html += "<thead><th>Circuit</th><th>CINR</th></thead>";
            html += "<tbody>";
            for (let i=0; i<event.Warnings.cinr.length; i++) {
                let w = event.Warnings.cinr[i];
                html += "<tr>";
                html += "<td>" + w.circuit_name + "</td>";
                html += "<td>" + w.cinr + "</td>";
                html += "</tr>";
            }
            html += "</tbody></table>";

            let container = document.getElementById("container");
            if (container) {
                container.innerHTML = html;
            }
        }
    }
}
