import { current_node_id, isRedacted, scaleNumber } from "../helpers";
import { Component, SelectableComponent } from "./component";
import * as echarts from 'echarts';
import { request_circuit_rtt_histo } from "../../wasm/wasm_pipe";
import { initEchartsWithTheme } from "../echart_themes";

export class CircuitRttHisto implements SelectableComponent {
    circuitId: string;
    divName: string;
    myChart: echarts.ECharts;
    x: any;
    chartMade: boolean = false;
    toasts: string = "";

    constructor(circuitId: string, useToasts: string = "") {
        this.divName = "circuitTpHisto";
        this.circuitId = circuitId;
        this.toasts = useToasts;
    }

    retarget(divName: string): void {
        this.divName = divName;
    }

    wireup(): void {
        this.myChart = initEchartsWithTheme(document.getElementById(this.divName) as HTMLDivElement);
        this.myChart.showLoading();
        request_circuit_rtt_histo(this.circuitId);
    }

    ontick(): void {
        request_circuit_rtt_histo(this.circuitId);
    }

    onmessage(event: any): void {
        if (event.msg == "RttCircuitHistogram") {
            //console.log(event);

            let series: echarts.SeriesOption[] = [];
            let legend: string[] = [];
            for (let i = 0; i < event.RttCircuitHistogram.histogram.length; i++) {
                let raw = event.RttCircuitHistogram.histogram[i];
                legend.push(raw[0]);
                let data: any = [];
                for (let j=0; j<raw[1].length; j++) {
                    data.push(raw[1][j]);
                }
                let histo: echarts.SeriesOption = {
                    name: raw[0],
                    type: "bar",
                    data: data,
                };
                series.push(histo);
            }
            this.x = [];
            for (let i=0; i<10; i++) {
                this.x.push(i*10);
            }
            //console.log(series);
            if (this.toasts != "") {
                let toastDiv = document.getElementById(this.toasts) as HTMLDivElement;
                let toasty = "";
                if (this.x[9] > 75) {
                    toastDiv.innerHTML = "<div class='alert alert-danger' role='alert'>The circuit is experiencing 90% utilization, more than 25% of the time (for the currently displayed period). That's often indicative of a need to upgrade.</div>";
                } else if (this.x[9] > 50) {
                    toastDiv.innerHTML = "<div class='alert alert-warning' role='alert'>The circuit is experiencing 90% utilization, more than 50% of the time (for the currently displayed period). That's often indicative of a need to upgrade.</div>";
                } else if (this.x[9] > 25) {
                    toastDiv.innerHTML = "<div class='alert alert-info' role='alert'>The circuit is experiencing 90% utilization, more than 75% of the time (for the currently displayed period). That's often indicative of a need to upgrade.</div>";
                }

                // Apply it
                if (toasty != "") {
                    toastDiv.innerHTML = toasty;
                }
            }

            let txt = {};
                if (isRedacted()) {
                    txt = { fontFamily: "Klingon" };
                }

            if (!this.chartMade) {
                this.myChart.hideLoading();
                var option: echarts.EChartsOption;
                this.myChart.setOption<echarts.EChartsOption>(
                    (option = {
                        animationDuration: 300,
                        //title: { text: "TCP Round-Trip Time Histogram" },
                        xAxis: {
                            type: 'category',
                            data: this.x
                        },
                        yAxis: {
                            type: 'value',
                            name: 'frequency',
                        },
                        series: series,
                        legend: {
                            orient: "horizontal",
                            right: 10,
                            top: "bottom",
                            data: legend,
                            textStyle: txt,
                        },
                    })
                );
                option && this.myChart.setOption(option);
                // this.chartMade = true;
            }
        }
    }
}