import { request_root_heat } from "../../wasm/wasm_pipe";
import { initEchartsWithTheme } from "../echart_themes";
import { PlotHeatMap } from "../heat_map";
import { current_node_id, getBadRtt, isRedacted, lerp_red_green, redactText } from "../helpers";
import { Component, SelectableComponent } from "./component";
import * as echarts from 'echarts';

export class RootHeat implements SelectableComponent {
    divName: string;
    myChart: echarts.ECharts | null = null;

    constructor() {
        this.divName ="rootHeat";
    }

    retarget(divName: string): void {
        this.divName = divName;
    }

    wireup(): void {
        request_root_heat();
    }

    ontick(): void {
        request_root_heat();
    }

    onmessage(event: any): void {
        if (event.msg == "RootHeat") {
            //console.log(event.RootHeat);
            PlotHeatMap(document.getElementById(this.divName) as HTMLDivElement, event.RootHeat);
        }
    }
}