import { Component } from "./component";
import { request_warnings } from "../../wasm/wasm_pipe";

export class WarningToast implements Component {
    count: number = 0;

    constructor() {
    }

    wireup(): void {
        request_warnings();
    }

    ontick(): void {
        if (this.count % 10 == 0) {
            request_warnings();
        }
        this.count++;
    }

    onmessage(event: any): void {
        if (event && event.msg == "Warnings") {
            let total_warnings = event.Warnings.interface_speed.length +
                event.Warnings.capacity.length + event.Warnings.cinr.length;

            if (total_warnings == 0) return;

            let html = "<div class='alert alert-warning' role='alert'>";
            html += "<span onclick='window.router.goto(\"warnings\")'>"
            let n_warnings = event.Warnings.interface_speed.length;
            if (n_warnings > 0) {
                html += "<i class='fa-solid fa-plug'></i> " + n_warnings + " interface speed warnings. ";
            }

            n_warnings = event.Warnings.capacity.length;
            if (n_warnings > 0) {
                html += "<i class='fa-solid fa-face-sad-tear'></i> " + n_warnings + " impossible capacity warnings. ";
            }

            n_warnings = event.Warnings.cinr.length;
            if (n_warnings > 0) {
                html += "<i class='fa-solid fa-signal'></i> " + n_warnings + " CINR warnings. ";
            }

            html += "</span></div>";


            let container = document.getElementById("alertHolder");
            if (container) {
                container.innerHTML = html;
            }
        }
    }
}