import { scaleNumber } from "../helpers";
import { mbps_to_bps } from "../pages/site_tree/site_tree";
import { Component } from "./component";
import { request_site_info } from "../../wasm/wasm_pipe";

export class SiteInfo implements Component {
    siteId: string;

    constructor(siteId: string) {
        this.siteId = siteId;
    }

    wireup(): void {
        request_site_info(decodeURI(this.siteId));        
    }

    ontick(): void {
        request_site_info(decodeURI(this.siteId));
    }

    onmessage(event: any): void {
        if (event.msg == "SiteInfo") {
            let head_div = "head_" + event.SiteInfo.node_id;
            let replace = false;

            if (document.getElementById(head_div) == null) {
                replace = true;
            }

            //console.log(event.data);
            let html = "<div id='" + head_div + "'><div class='row'><div class='col-12' style='font-weight: bold; text-align: center; background: navy; color: white;'>" + event.SiteInfo.node + "</div></div>"
            html += "<div class='row'><div class='col-6'><div class='card'><div class='card-body' id='siteInfo'>";
            html += "<table class='table table-striped'>";
            html += "<tr><td>Plan Max:</td><td>" + scaleNumber(event.SiteInfo.data.max_down * mbps_to_bps) + " down / " + scaleNumber(event.SiteInfo.data.max_up * mbps_to_bps) + " up</td></tr>";
            html += "<tr><td>Current Utilization:</td><td>" + scaleNumber(event.SiteInfo.data.current_down) + " down / " + scaleNumber(event.SiteInfo.data.current_up) + " up</td></tr>";
            html += "<tr><td>Current RTT:</td><td>" + event.SiteInfo.data.current_rtt / 100.0 + " ms</td></tr>";
            html += "</table>";
            html += "</div></div></div>";
            html += "<div class='col-6'><div class='card'><div class='card-body'><div id='oversub'>";

            let dlmax = event.SiteInfo.oversubscription.dlmax * mbps_to_bps;
            let dlmin = event.SiteInfo.oversubscription.dlmin * mbps_to_bps;
            let maxover = (dlmax / (event.SiteInfo.data.max_down * mbps_to_bps) * 100.0).toFixed(1);
            let minover = (dlmin / (event.SiteInfo.data.max_down * mbps_to_bps) * 100.0).toFixed(1);
            html += "<table class='table table-striped'>";
            html += "<tr><td>Total Subscribers:</td><td>" + event.SiteInfo.oversubscription.devicecount + "</td></tr>";
            html += "<tr><td>Total Subscriber Download (Ceiling):</td><td>" + scaleNumber(dlmax) + " (" + maxover + "%)</td></tr>";
            html += "<tr><td>Total Subscriber Download (Minimum Commit):</td><td>" + scaleNumber(dlmin) + " (" + minover + "%)</td></tr>";

            html += "</table>";
            html += "</div></div></div></div></div></div>";

            if (replace) {
                let div = document.getElementById("siteInfoList") as HTMLDivElement;
                div.innerHTML += html;
            } else {
                let div = document.getElementById(head_div) as HTMLDivElement;
                div.innerHTML = html;
            }
        }
    }
}