import { request_breadcrumbs } from "../../wasm/wasm_pipe";
import { current_node_id, makeUrl, redactText } from "../helpers";
import { Component } from "./component";

export class SiteBreadcrumbs implements Component {
    siteId: string;

    constructor(siteId: string) {
        this.siteId = siteId;
    }

    wireup(): void {
        let div = document.getElementById("siteName") as HTMLDivElement;
        div.innerHTML = "Loading, Please Wait... <i class='fa-solid fa-spinner fa-spin'></i>";
        request_breadcrumbs("site", this.siteId);
    }

    ontick(): void {
        request_breadcrumbs("site", this.siteId);
    }

    onmessage(event: any): void {
        //console.log("Parents", event);
        if (event.msg == "Breadcrumbs") {
            let div = document.getElementById("siteName") as HTMLDivElement;
            let html = "";
            let siteId: string = decodeURI(this.siteId);

            for (let i=0; i<event.Breadcrumbs.data.length; i++) {
                let node = event.Breadcrumbs.data[i];
                html += "<a style='text-decoration: underline;' onclick='window.router.goto(\"dashboard\")'> (Root) " + node.name + "</a>";
                html += " <i class=\"fa-solid fa-chevron-right\"></i> ";

                let reachedMe = false;
                for (let j=0; j<node.crumbs.length; j++) {
                    let crumb = node.crumbs[j];
                    if (crumb.name.toLowerCase() == siteId.toLowerCase()) {
                        reachedMe = true;
                        // Add the current site
                        html += "<span style='font-weight: bold'>" + siteId + "</span>";
                        html += " <i class=\"fa-solid fa-chevron-right\"></i> ";

                        html += "<select id='ch" + node.id + "' onchange='window.router.goto(document.getElementById(\"ch" + node.id + "\").value);'>";
                        html += "<option value=''>-- " + crumb.name + " Children --</option>";
                    } else if (!reachedMe) {
                        let url = makeUrl(crumb.site_type, crumb.id);
                        html += "<a onclick='window.router.goto(\"" + url + "\")' class='clickable'>" + redactText(crumb.name) + "</a>";
                        html += " <i class=\"fa-solid fa-chevron-right\"></i> ";
                    } else {
                        // Children
                        if (crumb.name != "Root") {
                            html += "<option value='" + makeUrl(crumb.site_type, crumb.id) + "'>" + crumb.name + "</option>";
                        }
                    }
                }
                if (reachedMe) {
                    html += "</select>";
                }
                html += "<br />";
            }

            div.style.fontSize = "9pt";
            div.innerHTML = html;

        } else if (event.msg == "SiteChildren") {
            //console.log("Children: ", event);
            let html = "<option value='' class='secret'>-- Children --</option>";
            for (let i=0; i<event.SiteChildren.data.length; i++) {
                html += "<option value='" + makeUrl(event.SiteChildren.data[i][0], event.SiteChildren.data[i][1]) + "'>" + event.SiteChildren.data[i][2] + "</option>";
            }
            let select = document.getElementById("siteChildren") as HTMLSelectElement;
            select.innerHTML = html;
            select.onchange = () => {
                let select = document.getElementById("siteChildren") as HTMLSelectElement;
                window.router.goto(select.value);
            };
        }
    }
}