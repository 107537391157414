import { request_root_heat_throughput } from "../../wasm/wasm_pipe";
import { PlotThroughputHeatMap } from "../heat_map";
import { SelectableComponent } from "./component";
import * as echarts from 'echarts';

export class RootHeatThroughput implements SelectableComponent {
    divName: string;
    myChart: echarts.ECharts | null = null;

    constructor() {
        this.divName ="rootHeat";
    }

    retarget(divName: string): void {
        this.divName = divName;
    }

    wireup(): void {
        request_root_heat_throughput();
    }

    ontick(): void {
        //console.log("tick");
        request_root_heat_throughput();
    }

    onmessage(event: any): void {
        if (event.msg == "RootHeatThroughput") {
            PlotThroughputHeatMap(document.getElementById(this.divName) as HTMLDivElement, event.RootHeatThroughput);
        }
    }
}