import { getBadRtt, lerp_red_green, makeUrl } from "./helpers";

export function PlotHeatMap(div: HTMLDivElement, event: any) {
    //console.log(event);
    let badRtt = getBadRtt();
    let html = "<table style='width: 100%'>";
    let first = true;

    if (event == null || event.data == null) {
        return;
    }

    // Top level we iterate the headings
    let colspan = event.columns + 1;
    for (let heading=0; heading<event.data.length; heading++) {
        html += "<tr style='margin-top: 10px; height: 20px; background: navy; color: white; text-align: center;'><td colspan='" + colspan + "' class='secret'><i class='fa-solid fa-server'></i> " + event.data[heading][0] + "</td></tr>";

        // Next level we iterate the entries
        for (let i=0; i<event.data[heading][1].length; i++) {
            let name = event.data[heading][1][i][0][0];
            let type = event.data[heading][1][i][0][1];
            let id = event.data[heading][1][i][0][2];
            let url = makeUrl(type, id);

            html += "<tr style='height: 20px'>";
            html += "<td style='text-align: right; font-size: 8pt; text-decoration: underline;' class='secret'>";
            html += "<a onclick='window.router.goto(\"" + url + "\")'>" + name + "</a></td>";

            let row = event.data[heading][1][i][1];

            for (let j=0; j<event.columns; j++) {
                let rtt = row[j];
                if (rtt.toFixed(0) == "0") {
                    html += "<td width='5%' style='background: #555; color: black; font-size: 8pt; text-align: center;'></td>";
                } else {
                    let color = lerp_red_green(0, badRtt, rtt);
                    html += "<td width='5%' style='background: " + color + "; color: black; font-size: 8pt; text-align: center;'>" + rtt.toFixed(0) + "</td>";
                }
            }

            html += "</tr>";
        }
    }

    // Add the footer
    let times: string[] = [];
    let timestamp = event.start;
    html += "<tr><td></td>";
    for (let i=0; i<event.columns; i++) {
        let d = new Date(timestamp * 1000);
        html += "<td style='padding-left: 2%; text-align: center; font-size: 8pt; writing-mode: vertical-rl; text-orientation: mixed;'>" + d.toLocaleString() + "</td>";
        timestamp += event.interval;
    }
    html += "</tr>";

    // Apply it all
    div.innerHTML = html;
}

export function PlotThroughputHeatMap(div: HTMLDivElement, event: any) {
    //console.log(event);
    let html = "<table style='width: 100%'>";
    let first = true;

    if (event == null || event.data == null) {
        return;
    }

    // Top level we iterate the headings
    let colspan = event.columns + 1;
    for (let heading=0; heading<event.data.length; heading++) {
        html += "<tr style='margin-top: 10px; height: 20px; background: navy; color: white; text-align: center;'><td colspan='" + colspan + "' class='secret'><i class='fa-solid fa-server'></i> " + event.data[heading][0] + "</td></tr>";

        // Next level we iterate the entries
        for (let i=0; i<event.data[heading][1].length; i++) {
            let name = event.data[heading][1][i][0][0];
            let type = event.data[heading][1][i][0][1];
            let id = event.data[heading][1][i][0][2];
            let url = makeUrl(type, id);

            html += "<tr style='height: 20px'>";
            html += "<td style='text-align: right; font-size: 8pt; text-decoration: underline;' class='secret'>";
            html += "<a onclick='window.router.goto(\"" + url + "\")'>" + name + "</a></td>";

            let row = event.data[heading][1][i][1];

            for (let j=0; j<event.columns; j++) {
                let down = row[j][0];
                let up = row[j][1];
                {
                    let color = lerp_red_green(0, 100, down);
                    html += "<td width='5%' style='border-left: 2px solid black; background: " + color + "; color: black; font-size: 8pt; text-align: center;'>" + down.toFixed(0) + "%</td>";
                }
                /*{
                    let color = lerp_red_green(0, 100, up);
                    html += "<td width='2.5%' style='background: " + color + "; color: black; font-size: 8pt; text-align: center;'>" + up.toFixed(0) + "%</td>";
                }*/
            }

            html += "</tr>";
        }
    }

    // Add the footer
    let times: string[] = [];
    let timestamp = event.start;
    html += "<tr><td></td>";
    for (let i=0; i<event.columns; i++) {
        let d = new Date(timestamp * 1000);
        html += "<td style='padding-left: 2%; text-align: center; font-size: 8pt; writing-mode: vertical-rl; text-orientation: mixed;'>" + d.toLocaleString() + "</td>";
        timestamp += event.interval;
    }
    html += "</tr>";

    // Apply it all
    div.innerHTML = html;
}