import { request_breadcrumbs } from "../../wasm/wasm_pipe";
import { current_node_id, makeUrl, redactText } from "../helpers";
import { Component } from "./component";

export class RootBreadcrumbs implements Component {
    constructor() {
    }

    wireup(): void {
        //console.log("Loading root breadcrumbs");
        let div = document.getElementById("siteName") as HTMLDivElement;
        div.innerHTML = "Loading, Please Wait... <i class='fa-solid fa-spinner fa-spin'></i>";
        request_breadcrumbs("root", "root");
    }

    ontick(): void {
        //console.log("Fetching parents for: " + current_node_id());
        request_breadcrumbs("root", "root");
    }

    onmessage(event: any): void {
        if (event.msg == "Breadcrumbs") {
            //console.log(event.Breadcrumbs.data);

            let html = "";
            for (let i=0; i<event.Breadcrumbs.data.length; i++) {
                let node = event.Breadcrumbs.data[i];
                html += "<a class='secret' style='text-decoration: underline; font-weight: bold' onclick='window.router.goto(\"dashboard\")'> (Root) " + node.name + "</a>";
                html += " <i class=\"fa-solid fa-chevron-right\"></i> ";
                html += "<select class='secret' id='ch" + node.id + "' onchange='window.router.goto(document.getElementById(\"ch" + node.id + "\").value);'>";
                html += "<option class='secret' value=''>-- " + node.name + " Children --</option>";
                for (let j=0; j<node.crumbs.length; j++) {
                    if (node.crumbs[j].name != "Root") {
                        html += "<option value='" + makeUrl(node.crumbs[j].node_id, node.crumbs[j].name) + "'>" + node.crumbs[j].name + "</option>";
                    }
                }
                html += "</select>";
                html += "<br />";
            }

            // Apply
            let div = document.getElementById("siteName") as HTMLDivElement;
            div.style.fontSize = "9pt";
            //div.style.columnCount = "2";
            if (div) div.innerHTML = html;
        }
    }
}