import { is_wasm_connected, send_wss_queue, initialize_wss, setup_time_period, update_time_period_display, time_period_button, time_period_absolute } from "../wasm/wasm_pipe";
import { Auth } from "./auth";
import { SiteRouter } from "./router";

export class Bus {
    ws: WebSocket;

    constructor() {
        setup_time_period();
        const currentUrlWithoutAnchors = window.location.href.split('#')[0].replace("https://", "").replace("http://", "");
        if (window.location.href.startsWith("https://")) {
            const url = "wss://" + currentUrlWithoutAnchors + "ws";
            initialize_wss(url);
        } else {
            const url = "ws://" + currentUrlWithoutAnchors + "ws";
            initialize_wss(url);
        }   
    }

    updateConnected() {
        //update_time_period_display();

        //console.log("Connection via WASM: " + is_wasm_connected());
        let indicator = document.getElementById("connStatus");
        if (indicator && is_wasm_connected()) {
            indicator.style.color = "green";

            // Clear the loader
            let loader = document.getElementById('SpinLoad');
            if (loader) {
                loader.style.display = "none";
            }
        } else if (indicator) {
            indicator.style.color = "red";
        }
    }

    changeTimePeriod(period: string) {
        time_period_button(period);
    }

    absoluteTimePeriod(start: string, end: string) {
        time_period_absolute(start, end);
    }

    sendQueue() {
        send_wss_queue();
    }

    getToken(): string {
        if (window.auth.hasCredentials && window.auth.token) {
            return window.auth.token;
        } else {
            return "";
        }
    }
}

// WASM callback
export function onAuthFail() {
    console.log("Ran on auth fail");
    window.auth.hasCredentials = false;
    window.login = null;
    window.auth.token = null;
    localStorage.removeItem("token");
    window.router.goto("login");
}

// WASM callback
export function onAuthOk(token: string, name: string, license_key: string, requires_paddle_setup: boolean) {
    window.auth.hasCredentials = true;
    window.login = { msg: "authOk", token: token, name: name, license_key: license_key };    
    window.auth.token = token;
    if (requires_paddle_setup) {
        window.router.goto("paddle");
    }// else {
    //    window.router.goto("dashboard");
    //}
}

// WASM Callback
export function onMessage(rawJson: string) {
    let json = JSON.parse(rawJson);
    //console.log(json);
    //console.log(Object.keys(json));
    if (json == "LoginFail") {
        //window.authFailed();
        let newJson = { msg: "LoginFail" };
        window.router.onMessage(newJson);
        return;
    }
    json.msg = Object.keys(json)[0];
    window.router.onMessage(json);
    if (json.msg && json.msg == "OrgPreferences") {
        let badRtt = json.OrgPreferences.bad_rtt;
        localStorage.setItem("badRtt", badRtt);
    }
}