import html from './template.html';
import { Page } from '../../page'
import { MenuPage } from '../../menu/menu';
import { Component } from '../../components/component';
import { ThroughputSiteChart } from '../../components/throughput_site';
import { SiteInfo } from '../../components/site_info';
import { RttChartSite } from '../../components/rtt_site';
import { SiteBreadcrumbs } from '../../components/site_breadcrumbs';
import { SiteHeat } from '../../components/site_heat';
import { SiteStackChart } from '../../components/site_stack';
import { request_node_list } from '../../../wasm/wasm_pipe';
import { MultiGraph } from '../../components/multi_graph';

export class SitePage implements Page {
    menu: MenuPage;
    components: Component[];
    siteId: string;
    selectedNode: string;

    constructor(siteId: string) {
        this.siteId = siteId;
        this.menu = new MenuPage("sitetreeDash");
        let container = document.getElementById('mainContent');
        if (container) {
            container.innerHTML = html;
        }
        this.components = [
            new SiteInfo(siteId),
            new SiteBreadcrumbs(siteId),
            new MultiGraph("tps", "tpHead", [
                ["Throughput", "250px", new ThroughputSiteChart(siteId)],
                ["Child Sites", "250px", new SiteStackChart(siteId, 1.0)],
            ]),
            new MultiGraph("rtt", "rttHead", [
                ["TCP Round-Trip Time", "250px", new RttChartSite(siteId, 1.0)]
            ]),
            new MultiGraph("heat", "heatHead", [
                ["TCP Round-Trip Time", "100%", new SiteHeat(siteId)],
            ]),
        ];
    }

    wireup() {
        this.components.forEach(component => {
            component.wireup();
        });
    }

    ontick(): void {
        this.menu.ontick();
        this.components.forEach(component => {
            component.ontick();
        });
        request_node_list();
    }

    onmessage(event: any) {
        if (event.msg) {
            this.menu.onmessage(event);

            this.components.forEach(component => {
                component.onmessage(event);
            });
        }
    }
}
