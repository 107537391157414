import { Component, SelectableComponent } from "./component";

export class MultiGraph implements Component {
    divName: string;
    parentDiv: HTMLDivElement;
    children: [string, string, SelectableComponent][];
    btnMap: any;
    selectedIndex: number;

    constructor(divName: string, headingDivName: string, children: [string, string, SelectableComponent][]) {
        this.divName = divName;
        this.parentDiv = document.getElementById(divName) as HTMLDivElement;
        let headingDiv = document.getElementById(headingDivName) as HTMLDivElement;
        this.children = children;
        this.btnMap = {};
        this.selectedIndex = 0;

        // Construct the HTML
        let headings = document.createElement('div');
        headings.classList.add("btn-group");
        headings.role = "group";
        headings.ariaLabel = "Selector";

        let workspaces: HTMLDivElement[] = [];

        for (let i = 0; i < this.children.length; i++) {
            // Make the button
            let btn = document.createElement('button');
            btn.type = "button";
            btn.classList.add("btn", "btn-sm");
            if (i == 0) {
                btn.classList.add("btn-primary");
            } else {
                btn.classList.add("btn-secondary");
            }
            btn.innerText = this.children[i][0];
            btn.id = this.divName + "_btn_" + i;
            btn.onclick = () => {
                for (const key in this.btnMap) {
                    let targetDivId = this.btnMap[key][1];
                    let targetDiv = document.getElementById(targetDivId) as HTMLDivElement;
                    let button = document.getElementById(key) as HTMLButtonElement;
                    button.classList.remove("btn-primary");
                    button.classList.remove("btn-secondary");
                    if (key == btn.id) {
                        this.selectedIndex = this.btnMap[key][0];
                        targetDiv.style.height = "auto";
                        targetDiv.style.overflow = "auto";
                        button.classList.add("btn-primary");
                    } else {
                        targetDiv.style.height = "0";
                        targetDiv.style.overflow = "none";
                        button.classList.add("btn-secondary");
                    }
                }
                this.children[this.selectedIndex][2].ontick();
            }
            headings.appendChild(btn);

            // Make the workspace
            let ws = document.createElement('div');
            ws.id = this.divName + "_ws_" + i;
            if (i == 0) {
                ws.style.height = "auto";
                ws.style.overflow = "auto";
            } else {
                ws.style.height = "0";
                ws.style.overflow = "hidden";
            }
            let target = document.createElement('div');
            target.style.height = this.children[i][1];
            target.id = this.divName + "_wsh_" + i;
            ws.appendChild(target);
            workspaces.push(ws);
            this.children[i][2].retarget(target.id);
            this.btnMap[btn.id] = [i, ws.id];
        }

        // Set the DOM
        this.parentDiv.innerHTML = "";
        headingDiv.append(headings);
        for (let i=0; i<workspaces.length; i++) {
            this.parentDiv.appendChild(workspaces[i]);
        }
    }

    wireup(): void {
        for (let i = 0; i < this.children.length; i++) {
            this.children[i][2].wireup();
        }
    }

    ontick(): void {
        /*for (let i = 0; i < this.children.length; i++) {
            this.children[i][2].ontick();
        }*/
        this.children[this.selectedIndex][2].ontick();
    }

    onmessage(event: any): void {
        for (let i = 0; i < this.children.length; i++) {
            this.children[i][2].onmessage(event);
        }
    }


}