import html from './template.html';
import { Page } from '../../page'
import { MenuPage } from '../../menu/menu';
import { Component } from '../../components/component';
import { request_org_preferences, set_org_prefernces, request_billing_info } from '../../../wasm/wasm_pipe';
import { isRedacted } from '../../helpers';

export class UserPage implements Page {
    menu: MenuPage;
    components: Component[]

    constructor() {
        this.menu = new MenuPage("menuUser");
        let container = document.getElementById('mainContent');
        if (container) {
            container.innerHTML = html;
        }
    }

    wireup() {
        request_org_preferences();
        request_billing_info();

        let item  = document.getElementById("rttThreshold");
        if (item) {
            item.onchange = () => {
                let input = item as HTMLInputElement;
                let value = input.value;
                let valueInt = parseInt(value);
                localStorage.setItem('badRtt', valueInt.toString());
            };
        }

        let ss  = document.getElementById("screenshotMode");
        if (ss) {
            ss.onchange = () => {
                let input = ss as HTMLInputElement;
                let value = input.checked;
                localStorage.setItem('screenshotMode', value.toString());
            };
            if (isRedacted()) {
                let input = ss as HTMLInputElement;
                input.checked = true;
            }
        }

        let btn = document.getElementById("savePrefs");
        if (btn) {
            btn.onclick = () => {
                let input = item as HTMLInputElement;
                let value = input.value;
                let valueInt = parseInt(value);
                localStorage.setItem('badRtt', valueInt.toString());
                set_org_prefernces(valueInt);
                localStorage.setItem('badRtt', valueInt.toString());
            }
        }
    }    

    ontick(): void {
    }

    onmessage(event: any) {
        if (event.msg && event.msg == "OrgPreferences") {
            let badRtt = event.OrgPreferences.bad_rtt;
            let item  = document.getElementById("rttThreshold");
            if (item) {
                let input = item as HTMLInputElement;
                input.value = badRtt;
            }
        } else if (event.msg && event.msg == "BillingInfo") {
            //console.log(event);
            let div = document.getElementById("billBody");
            let html = "";

            if (event.BillingInfo.status.Paddle) {
                let paddle = event.BillingInfo.status.Paddle;
                html += "<h3>Billing via Paddle</h3>";
                html += "<p><strong>Status:</strong> " + paddle.paddle_status + "</p>";
                html += "<p><strong>Signup Date:</strong> " + paddle.signup_date + "</p>";
                html += "<p><strong>Email on File:</strong> " + paddle.email + "</p>";
                html += "<p><strong>Next Payment Date:</strong> " + paddle.next_payment[2] + "</p>";
                html += "<p><strong>Next Payment Amount:</strong> " + paddle.next_payment[0] + " " + paddle.next_payment[1] + "</p>";
                html += "<a class='btn btn-primary' href='" + paddle.update_url + "'>Update Subscription</a> ";
                html += "<a class='btn btn-danger' href='" + paddle.cancel_url + "'>Cancel Subscription</a>";
            } else if (event.BillingInfo.status == "AlwaysFree") {
                html += "<h3>Always Free</h3>";
                html += "<p>There is no billing information on file for this account.</p>";
            }
            
            if (div) {
                div.innerHTML = html;
            }
        }
    }
}
