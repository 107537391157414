import html from './template.html';
import { Page } from '../../page'
import { getValueFromForm } from '../../helpers';
import { send_login, send_reset_password, send_recover_license } from '../../../wasm/wasm_pipe';
import { Modal } from 'bootstrap';

export class LoginPage implements Page {
    storedLicense: string | null = null;
    myModal: any = null;

    constructor() {
        let container = document.getElementById('mainContent');
        if (container) {
            container.innerHTML = html;

            // Check for stored licenses
            let stored_license = localStorage.getItem('license');
            if (stored_license) {
                let input = document.getElementById('license') as HTMLInputElement;
                if (input) {
                    input.value = stored_license;
                }

                let input2 = document.getElementById('lpLicense') as HTMLInputElement;
                if (input2) {
                    input2.value = stored_license;
                }

                this.storedLicense = stored_license;
            }

            // Fire up the login modal
            let loginModal = document.getElementById('loginModal');
            if (loginModal) {
                this.myModal = new Modal(loginModal, { backdrop: false, keyboard: false });
                let self = this;
                loginModal.addEventListener('shown.bs.modal', function () {
                    let focusTarget = "license";
                    if (self.storedLicense) {
                        focusTarget = "username";
                    }
                    let input = document.getElementById(focusTarget);
                    if (input) {
                        input.focus();
                    }
                });
                this.myModal.show();
            }
        }

        // Connect the button
        let button = document.getElementById('btnLogin');
        if (button) {
            button.onclick = this.onLogin;
        }

        // Connect the lost password button
        let lostPassword = document.getElementById('btnLostPassword');
        if (lostPassword) {
            lostPassword.onclick = this.onLostPassword;
        }

        // Connect the Lost ID button
        let lostId = document.getElementById('btnLostId');
        if (lostId) {
            lostId.onclick = this.onLostId;
        }

        // Bind the enter keys
        bindEnterToLogin("license");
        bindEnterToLogin("username");
        bindEnterToLogin("password");
    }

    wireup() {
    }

    onLogin() {
        if (this.myModal) this.myModal.hide();
        document.body.classList.remove('modal-open');
        document.body.style.overflow = "auto";

        let license = getValueFromForm('license');
        let username = getValueFromForm('username');
        let password = getValueFromForm('password');

        if (license == "") {
            alert("Please enter a license key");
            return;
        }
        if (username == "") {
            alert("Please enter a username");
            return;
        }
        if (password == "") {
            alert("Please enter a password");
            return;
        }

        localStorage.setItem('license', license);

        let btn = document.getElementById('btnLogin');
        if (btn) {
            btn.innerHTML = "<i class=\"fa-solid fa-spinner fa-spin\"></i>";
        }

        send_login(license, username, password);
    }

    onLostPassword() {
        let license = getValueFromForm('lpLicense');
        let username = getValueFromForm('lpUsername');
        if (license == "") {
            alert("Please enter a license key");
            return;
        }
        if (username == "") {
            alert("Please enter a username");
            return;
        }

        let btn = document.getElementById('btnLostPassword');
        if (btn) {
            btn.innerHTML = "<i class=\"fa-solid fa-spinner fa-spin\"></i>";
        }

        send_reset_password(license, username);
    }

    onLostId() {
        let license = getValueFromForm('lkEmail');
        if (license == "") {
            alert("Please enter the sign-up email address you used");
            return;
        }

        let btn = document.getElementById('btnLostKey');
        if (btn) {
            btn.innerHTML = "<i class=\"fa-solid fa-spinner fa-spin\"></i>";
        }

        send_recover_license(license);
    }

    onmessage(event: any) {
        if (event.msg) {
            if (event.msg == "LoginOk") {
                // TODO: Store the credentials globally
                //console.log("LOGIN OK");
                //console.log(event);
                window.login = event.LoginOk;
                window.auth.hasCredentials = true;
                window.auth.token = event.LoginOk.token;
                localStorage.setItem("token", event.LoginOk.token);
                if (event.LoginOk.requires_paddle_setup) {
                    //console.log("Redirecting to Paddle");
                    window.router.goto("paddle");
                } else {
                    window.router.goto("dashboard");
                }
            } else if (event.msg == "LoginFail") {
                let alert = document.getElementById('loginFailed');
                if (alert) alert.style.display = 'block';
                let btn = document.getElementById('btnLogin');
                if (btn) {
                    btn.textContent = "Login";
                }
            } else if (event.msg == "LostPassword") {
                alert(event.LostPassword.message);
            }
        }
    }

    ontick(): void {
        // Do nothing
    }
}

function bindEnterToLogin(id: string) {
    let input = document.getElementById(id);
    if (input) {
        input.addEventListener("keyup", function (event) {
            if (event.key === "Enter") {
                event.preventDefault();
                document.getElementById("btnLogin")?.click();
            }
        });
    }
}