import { isRedacted, scaleNumber } from "../helpers";
import { Component, SelectableComponent } from "./component";
import * as echarts from 'echarts';
import { request_rtt_chart_for_circuit } from "../../wasm/wasm_pipe";
import { initEchartsWithTheme } from "../echart_themes";
import { BandedRttGraph } from "../banded_graph";

export class RttChartCircuit implements SelectableComponent {
    divName: string;
    myChart: BandedRttGraph;
    chartMade: boolean = false;
    circuitId: string;

    constructor(circuitId: string) {
        this.circuitId = decodeURI(circuitId);
        this.divName = "rttChart";
    }

    retarget(divName: string): void {
        this.divName = divName;
    }

    wireup(): void {
        this.myChart = new BandedRttGraph(this.divName);
        request_rtt_chart_for_circuit(this.circuitId);
    }

    ontick(): void {
        request_rtt_chart_for_circuit(this.circuitId);
    }

    onmessage(event: any): void {
        if (event.msg == "RttChartCircuit") {
            this.myChart.buildChart(event.RttChartCircuit.nodes);
        }
    }
}