import html from './template.html';
import { Page } from '../../page'
import { MenuPage } from '../../menu/menu';
import { Component } from '../../components/component';
import { CircuitInfo } from '../../components/circuit_info';
import { ThroughputCircuitChart } from '../../components/throughput_circuit';
import { RttChartCircuit } from '../../components/rtt_circuit';
import { request_ext_device_info, request_ext_snr_graph, request_ext_capacity_graph } from "../../../wasm/wasm_pipe";
import * as echarts from 'echarts';
import { scaleNumber } from '../../helpers';
import { CircuitBreadcrumbs } from '../../components/circuit_breadcrumbs';
import { initEchartsWithTheme } from '../../echart_themes';
import { MultiGraph } from '../../components/multi_graph';
import { CircuitTpHisto } from '../../components/circuit_tp_histo';
import { CircuitRttHisto } from '../../components/rtt_circuit_histo';

export class CircuitPage implements Page {
    menu: MenuPage;
    components: Component[];
    circuitId: string;

    constructor(circuitId: string) {
        this.circuitId = decodeURI(circuitId);
        this.menu = new MenuPage("sitetreeDash");
        let container = document.getElementById('mainContent');
        if (container) {
            container.innerHTML = html;
        }
        this.components = [
            new CircuitBreadcrumbs(this.circuitId),
            new CircuitInfo(this.circuitId),
            new MultiGraph("throughputs", "tpHead", [
                ["Throughput", "250px", new ThroughputCircuitChart(this.circuitId)],
                ["Utilization Histogram", "250px", new CircuitTpHisto(this.circuitId, "toast")],
            ]),
            new MultiGraph("rtt", "rttHead", [
                ["RTT Histogram", "250px", new CircuitRttHisto(this.circuitId)],
                ["TCP Round-Trip Time", "250px", new RttChartCircuit(this.circuitId)],
            ]),
        ];
    }

    wireup() {
        this.components.forEach(component => {
            component.wireup();
        });
        request_ext_device_info(this.circuitId);
    }

    ontick(): void {
        this.menu.ontick();
        this.components.forEach(component => {
            component.ontick();
        });
    }

    onmessage(event: any) {
        if (event.msg) {
            this.menu.onmessage(event);

            this.components.forEach(component => {
                component.onmessage(event);
            });

            if (event.msg == "DeviceExt") {
                //console.log(event.DeviceExt.data);
                let div = document.getElementById("ext") as HTMLDivElement;
                let html = "";

                for (let i=0; i<event.DeviceExt.data.length; i++) {
                    let d = event.DeviceExt.data[i];
                    html += "<div class='row'>";

                    let name = d.name;
                    name += " (" + formatStatus(d.status);
                    name += ", " + d.model;
                    name += ", " + d.mode + " mode";
                    name += ", " + d.firmware;
                    name += ")";

                    html += "<div class='col-12'>";
                    html += "<div class='card'>";
                    html += "<div class='card-body'>";
                    html += "<h5 class='card-title'><i class='fa fa-wifi'></i> <span class='secret'>" + name + "</span></h5>";
                    //console.log(d);

                    html += "<table class='table table-striped'>";
                    for (let j=0; j<d.interfaces.length; j++) {
                        html += "<tr>";                        
                        let iface = d.interfaces[j];
                        html += "<td>" + iface.name + "</td>";
                        html += "<td>" + iface.mac + "</td>";
                        html += "<td>" + iface.ip_list + "</td>";
                        html += "<td>" + iface.status + "</td>";
                        html += "<td>" + iface.speed + "</td>";
                        html += "</tr>";                  
                    }
                    html += "</table>";
                    html += "<div class='card-body' id='extdev_" + d.device_id + "' style='height: 250px'></div>";
                    html += "<div class='card-body' id='extdev_cap_" + d.device_id + "' style='height: 250px'></div>";
                    request_ext_snr_graph(d.device_id);
                    request_ext_capacity_graph(d.device_id);

                    /*
                    html += "</div>";
                    html += "</div>";
                    html += "</div>";
                    html += "</div><div class='row'>";

                    html += "<div class='col-4'>";
                    html += "<div class='card'>";
                    html += "<div class='card-body' id='extdev_" + d.device_id + "' style='height: 250px'>";
                    html += "<p>Signal/noise graph</p>";
                    html += "</div>";
                    html += "</div>";
                    html += "</div>";
                    request_ext_snr_graph(window.graphPeriod, d.device_id);

                    html += "<div class='col-4'>";
                    html += "<div class='card'>";
                    html += "<div class='card-body' id='extdev_cap_" + d.device_id + "' style='height: 250px'>";
                    html += "<p>Capacity Graph</p>";
                    html += "</div>";
                    html += "</div>";
                    html += "</div>";
                    request_ext_capacity_graph(window.graphPeriod, d.device_id);

                    // End row
                    html += "</div>";*/
                }

                div.outerHTML = html;
            } else if (event.msg == "DeviceExtSnr") {
                //console.log(event);
                let div = document.getElementById("extdev_" + event.DeviceExtSnr.device_id) as HTMLDivElement;

                let sig: number[] = [];
                let n: number[] = [];
                let x: any[] = [];

                for (let i=0; i<event.DeviceExtSnr.data.length; i++) {
                    let d = event.DeviceExtSnr.data[i];
                    sig.push(d.signal);
                    n.push(d.noise);
                    x.push(d.date);
                }

                let series: echarts.SeriesOption[] = [];
                let signal: echarts.SeriesOption = {
                    name: "Signal",
                    type: "line",
                    data: sig,
                    symbol: 'none',
                };
                let noise: echarts.SeriesOption = {
                    name: "Noise",
                    type: "line",
                    data: n,
                    symbol: 'none',
                };
                series.push(signal);
                series.push(noise);

                let myChart: echarts.ECharts = initEchartsWithTheme(div);
                var option: echarts.EChartsOption;
                myChart.setOption<echarts.EChartsOption>(
                    (option = {
                        animationDuration: 300,
                        title: { text: "Signal/Noise" },
                        legend: {
                            orient: "horizontal",
                            right: 10,
                            top: "bottom",
                        },
                        xAxis: {
                            type: 'category',
                            data: x,
                        },
                        yAxis: {
                            type: 'value',
                            name: 'dB',
                        },
                        brush: {
                            toolbox: ['lineX'],
                            type: 'lineX',
                            xAxisIndex: 0,
                          },
                        series: series
                    })
                );
                option && myChart.setOption(option);
            } else if (event.msg == "DeviceExtCapacity") {
                //console.log(event);
                let div = document.getElementById("extdev_cap_" + event.DeviceExtCapacity.device_id) as HTMLDivElement;

                let down: number[] = [];
                let up: number[] = [];
                let x: any[] = [];

                for (let i=0; i<event.DeviceExtCapacity.data.length; i++) {
                    let d = event.DeviceExtCapacity.data[i];
                    down.push(d.dl);
                    up.push(d.ul);
                    x.push(d.date);
                }

                let series: echarts.SeriesOption[] = [];
                let signal: echarts.SeriesOption = {
                    name: "Download",
                    type: "line",
                    data: down,
                    symbol: 'none',
                };
                let noise: echarts.SeriesOption = {
                    name: "Upload",
                    type: "line",
                    data: up,
                    symbol: 'none',
                };
                series.push(signal);
                series.push(noise);

                let myChart: echarts.ECharts = initEchartsWithTheme(div);
                var option: echarts.EChartsOption;
                myChart.setOption<echarts.EChartsOption>(
                    (option = {
                        animationDuration: 300,
                        title: { text: "Estimated Capacity" },
                        legend: {
                            orient: "horizontal",
                            right: 10,
                            top: "bottom",
                        },
                        xAxis: {
                            type: 'category',
                            data: x,
                        },
                        yAxis: {
                            type: 'value',
                            name: 'Mbps',
                            axisLabel: {
                                formatter: function (val: number) {
                                    return scaleNumber(Math.abs(val), 0);
                                }
                            }
                        },
                        series: series
                    })
                );
                option && myChart.setOption(option);
            }
        }
    }
}

function formatStatus(status: String): String {
    switch (status) {
        case "active": return "<i class='fa fa-plug' style='color: green'></i> Active";
        case "disconnected" : return "<i class='fa fa-times' style='color: red'></i> Disconnected";
        default: return status;
    }
}