import { Component, SelectableComponent } from "./component";
import * as echarts from 'echarts';
import { request_site_heat } from "../../wasm/wasm_pipe";
import { current_node_id, getBadRtt, isRedacted, lerp_red_green, redactText } from "../helpers";
import { initEchartsWithTheme } from "../echart_themes";
import { PlotHeatMap } from "../heat_map";

export class SiteHeat implements SelectableComponent {
    divName: string;
    myChart: echarts.ECharts | null = null;
    siteId: string;
    multiplier: number = 1.0;

    constructor(siteId: string, multiplier: number = 1.0) {
        this.siteId = decodeURI(siteId);
        this.divName = "rootHeat";
        this.multiplier = multiplier;
    }

    retarget(divName: string): void {
        this.divName = divName;
    }

    wireup(): void {
        request_site_heat(this.siteId, current_node_id());
    }

    ontick(): void {
        request_site_heat(this.siteId, current_node_id());
    }

    onmessage(event: any): void {
        if (event.msg == "SiteHeat") {
            PlotHeatMap(document.getElementById(this.divName) as HTMLDivElement, event.SiteHeat);
        }
    }
}
