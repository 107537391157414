import { current_node_id, isRedacted, scaleNumber } from "../helpers";
import { Component, SelectableComponent } from "./component";
import * as echarts from 'echarts';
import { request_throughput_chart_for_site } from "../../wasm/wasm_pipe";
import { initEchartsWithTheme } from "../echart_themes";
import { BandedGraph } from "../banded_graph";

export class ThroughputSiteChart implements SelectableComponent {
    divName: string;
    myChart: BandedGraph;
    chartMade: boolean = false;
    siteId: string;

    constructor(siteId: string) {
        this.siteId = siteId;
        this.divName = "throughputChart";
    }

    retarget(divName: string): void {
        this.divName = divName;
    }

    wireup(): void {
        this.myChart = new BandedGraph(this.divName);
        request_throughput_chart_for_site(this.siteId);
    }

    ontick(): void {
        request_throughput_chart_for_site(this.siteId);
    }

    onmessage(event: any): void {
        if (event.msg == "BitsChart") {
            this.myChart.buildChart(event.BitsChart.nodes, 8.0);
        }
    }
}