import { isRedacted, scaleNumber } from "../helpers";
import { Component, SelectableComponent } from "./component";
import * as echarts from 'echarts';
import { request_rtt_chart } from "../../wasm/wasm_pipe";
import { initEchartsWithTheme } from "../echart_themes";
import { BandedRttGraph } from "../banded_graph";

export class RttChart implements SelectableComponent {
    divName: string;
    myChart: BandedRttGraph;
    chartMade: boolean = false;

    constructor() {
        this.divName = "rttChart";
    }

    retarget(divName: string): void {
        this.divName = divName;
    }

    wireup(): void {
        this.myChart = new BandedRttGraph(this.divName);
        request_rtt_chart();
    }

    ontick(): void {
        request_rtt_chart();
    }

    onmessage(event: any): void {
        if (event.msg == "RttChart") {
            //console.log(this.divName);
            this.myChart.buildChart(event.RttChart.nodes);
        }
    }
}