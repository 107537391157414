import html from './template.html';
import { Page } from '../../page'
import { MenuPage } from '../../menu/menu';
import { Component } from '../../components/component';
import { PacketsChart } from '../../components/packets';
import { ThroughputChart } from '../../components/throughput';
import { RttChart } from '../../components/rtt_graph';
import { RttHisto } from '../../components/rtt_histo';
import { RootHeat } from '../../components/root_heat';
import { SiteStackChart } from '../../components/site_stack';
import { RootBreadcrumbs } from '../../components/root_breadcrumbs';
import { request_node_list, request_org_preferences } from '../../../wasm/wasm_pipe';
import { WarningToast } from '../../components/warning_toast';
import { MultiGraph } from '../../components/multi_graph';
import { RootHeatThroughput } from '../../components/root_heat_tp';

export class DashboardPage implements Page {
    menu: MenuPage;
    components: Component[];
    selectedNode: string;

    constructor() {
        this.menu = new MenuPage("menuDash");
        let container = document.getElementById('mainContent');
        if (container) {
            container.innerHTML = html;
        }
        this.components = [
            new RootBreadcrumbs(),
            new MultiGraph("throughputs", "tpHead", [
                ["Throughput", "250px", new ThroughputChart()],
                ["Packets", "250px", new PacketsChart()],
                ["Child Sites", "250px", new SiteStackChart("root", 8.0)],
            ]),
            new MultiGraph("rtts", "latHead", [
                ["Histogram", "250px", new RttHisto()],
                ["Time_Series", "250px", new RttChart()],
            ]),
            new WarningToast(),
            new MultiGraph("rootHeat", "heatHead", [
                ["TCP Round-Trip Time", "100%", new RootHeat()],
                ["Throughput", "100%", new RootHeatThroughput()],
            ]),
        ];
    }

    wireup() {
        this.components.forEach(component => {
            component.wireup();
        });
        request_org_preferences();
    }    

    ontick(): void {
        this.menu.ontick();
        this.components.forEach(component => {
            component.ontick();
        });
    }

    onmessage(event: any) {
        if (event.msg) {
            this.menu.onmessage(event);

            this.components.forEach(component => {
                component.onmessage(event);
            });
        }
    }
}
