import { initEchartsWithTheme } from "./echart_themes";
import { isRedacted, scaleNumber } from "./helpers";

export class BandedGraph {
    chartMade: boolean = false;
    myChart: echarts.ECharts;

    constructor(divName: string) {
        this.myChart = initEchartsWithTheme(document.getElementById(divName) as HTMLDivElement);
        this.myChart.showLoading();
    }

    buildChart(nodes: any, multiplier = 1.0) {
        let series: echarts.SeriesOption[] = [];

        // Iterate all provides nodes and create a set of series for each,
        // providing upload and download banding per node.
        let x: any[] = [];
        let first = true;
        let legend: string[] = [];
        for (let i = 0; i < nodes.length; i++) {
            let node = nodes[i];
            legend.push(node.node_name);

            let d: number[][] = [];
            let u: number[][] = [];
            let l: number[][] = [];
            for (let j = 0; j < node.down.length; j++) {
                if (first) x.push(node.down[j].date);
                let ts = node.down[j].date;
                d.push([ts, node.down[j].value * multiplier]);
                u.push([ts, node.down[j].u * multiplier]);
                l.push([ts, node.down[j].l * multiplier]);
            }
            if (first) first = false;

            let min: echarts.SeriesOption = {
                name: "L",
                type: "line",
                data: l,
                symbol: 'none',
                stack: 'confidence-band-' + node.node_id,
                lineStyle: {
                    opacity: 0
                },
            };
            let max: echarts.SeriesOption = {
                name: "U",
                type: "line",
                data: u,
                symbol: 'none',
                stack: 'confidence-band-' + node.node_id,
                lineStyle: {
                    opacity: 0
                },
                areaStyle: {
                    color: '#ccc',
                    opacity: 0.4,
                },
            };
            let val: echarts.SeriesOption = {
                name: node.node_name,
                type: "line",
                data: d,
                symbol: 'none',
            };

            series.push(min);
            series.push(max);
            series.push(val);

            // Do the same for upload
            d = [];
            u = [];
            l = [];
            for (let j = 0; j < node.down.length; j++) {
                let ts = node.up[j].date;
                d.push([ts, 0.0 - node.up[j].value * multiplier]);
                u.push([ts, 0.0 - node.up[j].u * multiplier]);
                l.push([ts, 0.0 - node.up[j].l * multiplier]);
            }

            min = {
                name: "L",
                type: "line",
                data: l,
                symbol: 'none',
                stack: 'confidence-band-' + node.node_id,
                lineStyle: {
                    opacity: 0
                },
            };
            max = {
                name: "U",
                type: "line",
                data: u,
                symbol: 'none',
                stack: 'confidence-band-' + node.node_id,
                lineStyle: {
                    opacity: 0
                },
                areaStyle: {
                    color: '#ccc',
                    opacity: 0.4,
                },
            };
            val = {
                name: node.node_name,
                type: "line",
                data: d,
                symbol: 'none',
            };

            series.push(min);
            series.push(max);
            series.push(val);
        }

        if (!this.chartMade) {
            this.myChart.hideLoading();
            var option: echarts.EChartsOption;

            let txt = {};
            if (isRedacted()) {
                txt = { fontFamily: "Klingon" };
            }

            this.myChart.setOption<echarts.EChartsOption>(
                (option = {
                    //title: { text: "Bits/second by Shaper" },
                    animationDuration: 300,
                    tooltip: {
                        trigger: "axis",
                        formatter: function (params: any) {
                            let ret = "";
                            for (let i = 0; i < params.length; i += 3) {
                                ret += new Date(params[i + 2].value[0]).toLocaleString() + " => ";
                                if (params[i + 2].value[1] > 0) {
                                    ret += params[i + 2].seriesName + ": " + scaleNumber(Math.abs(params[i + 2].value[1])) + " ⬇️<br/>";
                                } else {
                                    ret += params[i + 2].seriesName + ": " + scaleNumber(Math.abs(params[i + 2].value[1])) + " ⬆️<br/>";
                                }
                            }
                            return ret;
                        },
                        position: function (point, params, dom, rect, size) {
                            // Calculate the position of the tooltip based on the point, rect, and size
                            var x = point[0];
                            var y = point[1];
                            var obj = [x,y];
                            
                            // Adjust if tooltip goes beyond the right boundary
                            if (x + size.contentSize[0] > size.viewSize[0]) {
                              obj[0] = x - size.contentSize[0];
                            }
                            
                            // Adjust if tooltip goes beyond the bottom boundary
                            if (y + size.contentSize[1] > size.viewSize[1]) {
                              obj[1] = 0;
                            }
                            
                            return obj;
                          }
                    },
                    legend: {
                        orient: "horizontal",
                        right: 10,
                        top: "bottom",
                        data: legend,
                        textStyle: txt,
                    },
                    xAxis: {
                        type: 'time',
                        //data: x,
                        axisLabel: {
                            formatter: function (val: number) {
                                return new Date(val).toLocaleString();
                            },
                            hideOverlap: true
                        }
                    },
                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            formatter: function (val: number) {
                                return scaleNumber(Math.abs(val));
                            }
                        }
                    },
                    brush: {
                        toolbox: ['lineX'],
                        type: 'lineX',
                        xAxisIndex: 0,
                      },
                    series: series
                })
            );
            option && this.myChart.setOption(option);
            // this.chartMade = true;
        }
    }
}

export class BandedRttGraph {
    chartMade: boolean = false;
    myChart: echarts.ECharts;

    constructor(divName: string) {
        this.myChart = initEchartsWithTheme(document.getElementById(divName) as HTMLDivElement);
        this.myChart.showLoading();
    }

    buildChart(nodes: any) {
        let series: echarts.SeriesOption[] = [];

        // Iterate all provides nodes and create a set of series for each,
        // providing upload and download banding per node.
        let x: any[] = [];
        let first = true;
        let legend: string[] = [];
        for (let i = 0; i < nodes.length; i++) {
            let node = nodes[i];
            legend.push(node.node_name);
            //console.log(node);

            let d: number[][] = [];
            let u: number[][] = [];
            let l: number[][] = [];
            for (let j = 0; j < node.rtt.length; j++) {
                if (first) x.push(node.rtt[j].date);
                let ts = node.rtt[j].date;
                d.push([ts, node.rtt[j].value]);
                u.push([ts, node.rtt[j].u]);
                l.push([ts, node.rtt[j].l]);
            }
            if (first) first = false;

            let min: echarts.SeriesOption = {
                name: "L",
                type: "line",
                data: l,
                symbol: 'none',
                stack: 'confidence-band-' + node.node_id,
                lineStyle: {
                    opacity: 0
                },
            };
            let max: echarts.SeriesOption = {
                name: "U",
                type: "line",
                data: u,
                symbol: 'none',
                stack: 'confidence-band-' + node.node_id,
                lineStyle: {
                    opacity: 0
                },
                areaStyle: {
                    color: '#ccc'
                },
            };
            let val: echarts.SeriesOption = {
                name: node.node_name,
                type: "line",
                data: d,
                symbol: 'none',
            };

            series.push(min);
            series.push(max);
            series.push(val);
        }

        if (!this.chartMade) {
            this.myChart.hideLoading();
            let txt = {};
            if (isRedacted()) {
                txt = { fontFamily: "Klingon" };
            }
            var option: echarts.EChartsOption;
            this.myChart.setOption<echarts.EChartsOption>(
                (option = {
                    //title: { text: "TCP Round-Trip Time" },
                    animationDuration: 300,
                    tooltip: {
                        trigger: "axis",
                        formatter: function (params: any) {
                            let ret = "";
                            for (let i = 0; i < params.length; i += 3) {
                                if (params[i + 2].value > 0) {
                                    ret += "<strong>" + params[i + 2].seriesName + "</strong>: " + params[i + 2].value.toFixed(1) + " ms<br/>";
                                }
                            }
                            return ret;
                        }
                    },
                    legend: {
                        orient: "horizontal",
                        right: 10,
                        top: "bottom",
                        data: legend,
                        textStyle: txt,
                    },
                    xAxis: {
                        type: 'time',
                        //data: x,
                        axisLabel: {
                            formatter: function (val: number) {
                                return new Date(val).toLocaleString();
                            },
                            hideOverlap: true
                        }
                    },
                    yAxis: {
                        type: 'value',
                        name: 'ms',
                    },
                    brush: {
                        toolbox: ['lineX'],
                        type: 'lineX',
                        xAxisIndex: 0,
                      },
                    series: series
                })
            );
            option && this.myChart.setOption(option);
            // this.chartMade = true;
        }
    }
}

export class SiteStackGraph {
    chartMade: boolean = false;
    myChart: echarts.ECharts;
    multiplier: number = 1.0;

    constructor(divName: string, multiplier?: number) {
        this.myChart = initEchartsWithTheme(document.getElementById(divName) as HTMLDivElement);
        this.myChart.showLoading();
        if (multiplier) {
            this.multiplier = multiplier;
        }
    }

    buildChart(nodes: any[]) {
        let series: echarts.SeriesOption[] = [];

        // Iterate all provides nodes and create a set of series for each,
        // providing upload and download banding per node.
        let x: any[] = [];
        let first = true;
        let legend: string[] = [];
        for (let i = 0; i < nodes.length; i++) {
            let node = nodes[i];
            //console.log(node);
            if (node.node_name != "Root") {
                legend.push(node.node_name);
                //legend.push(node.node_name + " UL");
                //console.log(node);

                let d: number[][] = [];
                for (let j = 0; j < node.download.length; j++) {
                    if (first) x.push(node.download[j][0]);
                    let ts = node.download[j][0];
                    d.push([ts, node.download[j][1] * this.multiplier]);
                }
                if (first) first = false;

                d.sort((a, b) => {
                    if (a[0] < b[0]) return -1;
                    if (a[0] > b[0]) return 1;
                    return 0;
                });

                let val: echarts.SeriesOption = {
                    name: node.node_name,
                    type: "line",
                    data: d,
                    symbol: 'none',
                    //stack: 'Download',
                    //emphasis: { focus: 'series' },
                    //areaStyle: {},
                };

                series.push(val);

                // Do the same for upload
                d = [];
                for (let j = 0; j < node.upload.length; j++) {
                    if (first) x.push(node.upload[j][0]);
                    let ts = node.upload[j][0];
                    d.push([ts, 0.0 - (node.upload[j][1] * this.multiplier)]);
                }
                if (first) first = false;

                val = {
                    name: node.node_name,
                    type: "line",
                    data: d,
                    symbol: 'none',
                    //stack: 'upload',
                    //areaStyle: {},
                };

                series.push(val);
            }
        }

        if (!this.chartMade) {
            this.myChart.hideLoading();
            var option: echarts.EChartsOption;
            let txt = { fontSize: 8, fontFamily: "Sans-Serif" };
            if (isRedacted()) {
                txt = { fontFamily: "Klingon", fontSize: 8 };
            }
            this.myChart.setOption<echarts.EChartsOption>(
                (option = {
                    //title: { text: "Child Node Throughput (Bits - Max)" },
                    animationDuration: 300,
                    tooltip: {
                        trigger: "axis",
                        formatter: function (params: any) {
                            let result = "";
                            for (let i = 0; i < params.length; i += 2) {
                                let siteName = params[i].seriesName;
                                siteName += " (⬇️" + scaleNumber(params[i].value[1]) + " / ⬆️" + scaleNumber(Math.abs(params[i + 1].value[1])) + ")";
                                result += `${siteName}<br />`;
                            }
                            return result;
                            //return `${params.seriesName}<br />
                            //    ${params.name}: ${params.data.value}<br />
                            //    ${params.data.name1}: ${params.data.value1}`;
                        },
                        position: function (point, params, dom, rect, size) {
                            // Calculate the position of the tooltip based on the point, rect, and size
                            var x = point[0];
                            var y = point[1];
                            var obj = [x,y];
                            
                            // Adjust if tooltip goes beyond the right boundary
                            if (x + size.contentSize[0] > size.viewSize[0]) {
                              obj[0] = x - size.contentSize[0];
                            }
                            
                            // Adjust if tooltip goes beyond the bottom boundary
                            if (y + size.contentSize[1] > size.viewSize[1]) {
                              obj[1] = 0;
                            }
                            
                            return obj;
                          }
                    },
                    grid: {
                        //right: '20%',
                    },
                    legend: {
                        //orient: "vertical",
                        right: 0,
                        top: "bottom",
                        data: legend,
                        textStyle: txt,
                    },
                    xAxis: {
                        type: 'time',
                        //data: x,
                        position: 'bottom',
                        axisLabel: {
                            formatter: function (val: number) {
                                return new Date(val).toLocaleString();
                            },
                            hideOverlap: true,
                        }
                    },
                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            formatter: function (val: number) {
                                return scaleNumber(Math.abs(val));
                            }
                        }
                    },
                    series: series
                })
            );
            option && this.myChart.setOption(option);
            // this.chartMade = true;
        }
    }
}