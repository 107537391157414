import { current_node_id, isRedacted, lerpColor, scaleNumber } from "../helpers";
import { Component, SelectableComponent } from "./component";
import * as echarts from 'echarts';
import { request_rtt_histogram } from "../../wasm/wasm_pipe";
import { initEchartsWithTheme } from "../echart_themes";

export class RttHisto implements SelectableComponent {
    divName: string;
    myChart: echarts.ECharts;
    x: any;
    chartMade: boolean = false;

    constructor() {
        this.divName = "rttHisto";
    }

    retarget(divName: string): void {
        this.divName = divName;
    }

    wireup(): void {
        this.myChart = initEchartsWithTheme(document.getElementById(this.divName) as HTMLDivElement);
        this.myChart.showLoading();
        request_rtt_histogram();
    }

    ontick(): void {
        request_rtt_histogram();
    }

    onmessage(event: any): void {
        if (event.msg == "RttHistogram") {
            //console.log(event);

            let series: echarts.SeriesOption[] = [];
            let legend: string[] = [];
            for (let i = 0; i < event.RttHistogram.histogram.length; i++) {
                let raw = event.RttHistogram.histogram[i];
                legend.push(raw[0]);
                let data: any = [];
                for (let j=0; j<raw[1].length; j++) {
                    data.push(raw[1][j]);
                }
                let histo: echarts.SeriesOption = {
                    name: raw[0],
                    type: "bar",
                    data: data,
                };
                series.push(histo);
            }
            this.x = [];
            for (let i=0; i<20; i++) {
                this.x.push(i*10);
            }
            //console.log(series);

            let txt = {};
                if (isRedacted()) {
                    txt = { fontFamily: "Klingon" };
                }

            if (!this.chartMade) {
                this.myChart.hideLoading();
                var option: echarts.EChartsOption;
                this.myChart.setOption<echarts.EChartsOption>(
                    (option = {
                        animationDuration: 300,
                        //title: { text: "TCP Round-Trip Time Histogram" },
                        xAxis: {
                            type: 'category',
                            data: this.x,                            
                        },
                        yAxis: {
                            type: 'value',
                            name: 'frequency',
                        },
                        series: series,
                        legend: {
                            orient: "horizontal",
                            right: 10,
                            top: "bottom",
                            data: legend,
                            textStyle: txt,
                        },
                    })
                );
                option && this.myChart.setOption(option);
                // this.chartMade = true;
            }
        }
    }
}