import { AllAccessPointsPage } from './pages/all_aps/all_aps';
import { AllSitesPage } from './pages/all_sites/all_sites';
import { AccessPointPage } from './pages/ap/ap';
import { Auth } from './auth';
import { CircuitPage } from './pages/circuit/circuit';
import { DashboardPage } from './pages/dashboard/dashboard';
import { isRedacted } from './helpers';
import { LoginPage } from './pages/login/login';
import { PaddlePage } from './pages/paddle/paddle';
import { Page } from './page';
import { ShaperNodePage } from './pages/shapernode/shapernode';
import { ShaperNodesPage } from './pages/shapernodes/shapernodes';
import { SitePage } from './pages/site/site';
import { SiteTreePage } from './pages/site_tree/site_tree';
import { UserPage } from './pages/userpage/userpage';
import { WarningsPage } from './pages/warnings/warnings';
import { ReportsPage } from './pages/reports/reports';
import { AllSitesThroughputPage } from './pages/all_site_tp/all_site_tp';
import { AllApsThroughputPage } from './pages/all_aps_tp/all_aps_tp';

export class SiteRouter {
    curentPage: Page | undefined;
    currentAnchor: string;

    constructor() {
        this.curentPage = undefined;
        this.currentAnchor = "";
    }

    initialRoute() {
        if (window.auth.hasCredentials) {
            let container = document.getElementById('main');
            if (container) {
                container.innerHTML = "<i class=\"fa-solid fa-spinner fa-spin\"></i>";
            }
            window.setTimeout(() => {         
                let target = window.location.hash;
                if (target == "" || target == "#") {
                    target = "dashboard";
                }
                this.goto(target);
            }, 1000);
        } else {
            this.goto("login");
        }
    }

    ontick() {
        if (this.curentPage) {
            this.curentPage.ontick();
        }
    }

    // Handle actual navigation between pages
    goto(page: string) {
        // Clear any search results
        let r = document.getElementById("searchResults");
        if (r) {
            r.style.display = "none";
        }

        // Redaction
        if (isRedacted()) {
            //console.log("Redacting");
            document.documentElement.style.setProperty('--redact', 'Klingon');
        } else {
            //console.log("Unredacting");
            document.documentElement.style.setProperty('--redact', 'Sans-Serif');
        }

        page = page.replace('#', '');
        //console.log("Navigate to " + page)
        let split = page.split(':');
        switch (split[0].toLowerCase()) {
            case "login": {
                this.currentAnchor = "login";
                this.curentPage = new LoginPage();
                break;
            }
            case "paddle": {
                this.currentAnchor = "paddle";
                this.curentPage = new PaddlePage();
                break;
            }
            case "dashboard": {
                this.currentAnchor = "dashboard";
                this.curentPage = new DashboardPage();
                break;
            }
            case "shapernodes": {
                this.currentAnchor = "shapernodes";
                this.curentPage = new ShaperNodesPage();
                break;
            }
            case "shapernode": {
                this.currentAnchor = "shapernode:" + split[1] + ":" + split[2];
                this.curentPage = new ShaperNodePage(split[1], split[2]);
                break;
            }
            case "sitetree": {
                this.currentAnchor = "sitetree";
                this.curentPage = new SiteTreePage();
                break;
            }
            case "allsites": {
                this.currentAnchor = "allsites";
                this.curentPage = new AllSitesPage();
                break;
            }
            case "allaps": {
                this.currentAnchor = "allaps";
                this.curentPage = new AllAccessPointsPage();
                break;
            }
            case "site": {
                this.currentAnchor = "site:" + split[1];
                this.curentPage = new SitePage(split[1]);
                break;
            }
            case "ap": {
                this.currentAnchor = "ap:" + split[1];
                this.curentPage = new AccessPointPage(split[1]);
                break;
            }
            case "circuit": {
                this.currentAnchor = "circuit:" + split[1];
                this.curentPage = new CircuitPage(split[1]);
                break;
            }
            case "logout": {
                window.auth.logout();
                break;
            }
            case "user": {
                this.currentAnchor = "user";
                this.curentPage = new UserPage();
                break;
            }
            case "warnings": {
                this.currentAnchor = "warningsDash";
                this.curentPage = new WarningsPage();
                break;
            }
            case "reports": {
                this.currentAnchor = "reports";
                this.curentPage = new ReportsPage();
                break;
            }
            case "allsitestp": {
                this.currentAnchor = "allsitestp";
                this.curentPage = new AllSitesThroughputPage();
                break;
            }
            case "allapstp": {
                this.currentAnchor = "allapstp";
                this.curentPage = new AllApsThroughputPage();
                break;
            }
            default: {
                alert("I don't know how to go to: " + split[0].toLowerCase());
                this.goto("dashboard");
                return;
            }
        }
        window.location.hash = this.currentAnchor;
        this.curentPage.wireup();
    }

    onMessage(event: any) {
        //console.log(event);
        if (this.curentPage) {
            this.curentPage.onmessage(event);
        }
    }
}