import html from './template.html';
import { Page } from '../../page'
import { MenuPage } from '../../menu/menu';
import { Component } from '../../components/component';
import { request_all_sites_max_tp_histo } from '../../../wasm/wasm_pipe';
import { lerp_red_green, lerp_white_blue } from '../../helpers';

export class AllSitesThroughputPage implements Page {
    menu: MenuPage;
    components: Component[];
    selectedNode: string;
    div: HTMLDivElement;
    loading: boolean = false;

    constructor() {
        this.menu = new MenuPage("reportsDash");
        let container = document.getElementById('mainContent');
        if (container) {
            container.innerHTML = html;
        }
        this.components = [
        ];
        this.div = document.getElementById("all_sites") as HTMLDivElement;
    }

    wireup() {
        //console.log("Wireup happened");
        this.components.forEach(component => {
            component.wireup();
        });
        this.loading = true;
        request_all_sites_max_tp_histo();
    }

    ontick(): void {
        this.menu.ontick();
        this.components.forEach(component => {
            component.ontick();
        });
        if (!this.loading) {
            request_all_sites_max_tp_histo();
            this.loading = true;
        }
    }

    onmessage(event: any) {
        if (event.msg) {
            this.menu.onmessage(event);

            this.components.forEach(component => {
                component.onmessage(event);
            });

            if (event.msg == "AllSitesThroughputHisto") {
                //console.log(event);

                let target = document.getElementById("target") as HTMLDivElement;
                let html = "<h5>Access Points At or Near Capacity</h5>";
                html += "<table class='table table-striped'>";
                html += "<thead>";
                html += "<th>Shaper</th><th>Site</th><th>Configured Capacity</th>";
                for (let i=0; i<10; i++) {
                    html += "<th>" + (i*10).toString() + "-" + (((i+1)*10)-1).toString() + "%</th>";
                }
                html += "</thead>";
                
                for (let i=0; i<event.AllSitesThroughputHisto.data.length; i++) {
                    let node = event.AllSitesThroughputHisto.data[i];
                    html += "<tr>";
                    html += "<td class='secret'>" + node[0] + "</td>";
                    html += "<td><a style='text-decoration: underline' onclick='window.router.goto(\"site:" + node[1] + "\")'>" + node[1] + "</a></td>";
                    html += "<td>" + node[2] + " Mbps</td>";
                    for (let j=0; j<10; j++) {
                        let bg = lerp_white_blue(0, 100, node[3][j]);
                        html += "<td style='background: " + bg + "; color: black;'>" + node[3][j] + "%</td>";
                    }
                    html += "</tr>";
                }


                target.innerHTML = html;
                this.loading = false;
            }
        }
    }
}

