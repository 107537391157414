import html from './template.html';
import { Page } from '../../page'
import { MenuPage } from '../../menu/menu';
import { Component } from '../../components/component';
import { getBadRtt, lerp_red_green, makeUrl, redactText, rttColor, scaleNumber, siteIcon, usageColor } from '../../helpers';
import { request_all_aps_heat } from '../../../wasm/wasm_pipe';
import { PlotHeatMap } from '../../heat_map';

export class AllAccessPointsPage implements Page {
    menu: MenuPage;
    components: Component[];
    selectedNode: string;
    div: HTMLDivElement;
    loading: boolean = false;

    constructor() {
        this.menu = new MenuPage("reportsDash");
        let container = document.getElementById('mainContent');
        if (container) {
            container.innerHTML = html;
        }
        this.components = [
        ];
        this.div = document.getElementById("all_sites") as HTMLDivElement;
    }

    wireup() {
        //console.log("Wireup happened");
        this.components.forEach(component => {
            component.wireup();
        });
        this.loading = true;
        request_all_aps_heat();
    }

    ontick(): void {
        this.menu.ontick();
        this.components.forEach(component => {
            component.ontick();
        });
        if (!this.loading) {
            this.loading = true;
            request_all_aps_heat();
        }
    }

    onmessage(event: any) {
        if (event.msg) {
            this.menu.onmessage(event);

            this.components.forEach(component => {
                component.onmessage(event);
            });

            //console.log(event);
            if (event.msg = "AllApsHeat") {
                //console.log(event);
                PlotHeatMap(this.div, event.AllApsHeat);
                //console.log("Rendered");
                this.loading = false;
            }
        }
    }
}

