import { request_site_stack } from "../../wasm/wasm_pipe";
import { SiteStackGraph } from "../banded_graph";
import { initEchartsWithTheme } from "../echart_themes";
import { current_node_id, isRedacted, scaleNumber } from "../helpers";
import { Component, SelectableComponent } from "./component";
import * as echarts from 'echarts';

export class SiteStackChart implements SelectableComponent {
    divName: string;
    myChart: SiteStackGraph;
    chartMade: boolean = false;
    siteId: string;
    multiplier: number = 8.0;

    constructor(siteId: string, multiplier: number = 1.0) {
        this.siteId = siteId;
        this.divName = "siteStack";
        this.multiplier = multiplier;        
    }

    retarget(divName: string): void {
        this.divName = divName;
    }

    wireup(): void {
        this.myChart = new SiteStackGraph(this.divName);
        request_site_stack(this.siteId, current_node_id());
    }

    ontick(): void {
        request_site_stack(this.siteId, current_node_id());
    }

    onmessage(event: any): void {
        if (event.msg == "SiteStack") {
            this.myChart.buildChart(event.SiteStack.nodes);
        }
    }
}